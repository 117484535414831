import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFireDatabase,
  AngularFireList,
} from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private dbPath = 'events/';
  token: string = '';

  notificationsRef?: AngularFireList<any>;

  constructor(
    private db: AngularFireDatabase,
    private fs: AngularFirestore,
    private af: AngularFireAuth,
    private http: HttpClient
  ) {
    this.notificationsRef = this.db.list(this.dbPath);
  }

  generateToken() {
    let dataUser: any = localStorage.getItem('user');
    dataUser = JSON.parse(dataUser);
    const data = {
      user: dataUser.user_name,
    };
    return this.http.post(environment.apiUrl + '/firebase/token', data, {
      headers: this.headers,
    });
  }

  async user(token: string) {
    let user = await this.af.signInWithCustomToken(token);
    let name = await this.af.currentUser;
    return name;
  }

  getAll(uid: string) {
    //const name: any = this.user(token);
    return this.db.list(this.dbPath + uid);
  }

  deleteSelectedNotification(uid: string,key: string): Promise<void> {
    if (this.notificationsRef) {
      return this.db.object(this.dbPath + uid + '/' + key).remove();
    } else {
      return Promise.reject('notificationsRef no está definido');
    }
  }
  

  deleteAll(uid: string): Promise<void>{
    return this.db.object(this.dbPath + uid).remove()
  }
}
