import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
  ) {}

  authUser(params: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/account/user/login', params, {
      headers: this.headers,
    });
  }

  logOut(): any {
    localStorage.removeItem('menu');
    localStorage.removeItem('user');
  }
}
