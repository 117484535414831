<p-menubar id="navBar">
  <ng-template pTemplate="start">
    <div class="left-container">
      <img
        src="../../../assets/gifs/logo-dashboard.gif"
        height="40"
        class="mr-2"
        [routerLink]="['/dashboard/inbox']"
      />
      <app-notification-button class="ml-6 align-content-center"></app-notification-button>
    </div>
  </ng-template>
  <div class="buttons-container">
    <button
      (click)="logOut()"
      pButton
      type="button"
      class="button-icon p-button-danger"
    >
      <i class="p-button-sm pi pi-sign-out"></i>
    </button>
    <button
      (click)="hiddenSidebar()"
      pButton
      type="button"
      class="button-icon p-button-warning"
    >
      <i class="p-button-sm pi pi-bars"></i>
    </button>
  </div>
</p-menubar>
<app-sidebar-menu></app-sidebar-menu>
