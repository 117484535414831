import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let dataUser: any = localStorage.getItem('user');
    dataUser = JSON.parse(dataUser);
    let dataMenu: any = localStorage.getItem('menu');
    dataMenu = JSON.parse(dataMenu);

    if (dataUser && dataMenu) {
      return true;
    }
		this.router.navigate([""]);
    return false;
  }
}
