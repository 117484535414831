<p-overlayPanel #notifications [style]="{width: '290px'}">
    <ng-template pTemplate>
        <div style="height: 400px; margin-bottom: 10px; overflow-y: auto;">
            <div *ngFor="let item of allInfo">
              <p-card *ngIf="item.type != 6 && item.type != 5" id="okCard" class="notification-card">
                <div>{{ item.content }}</div>
              </p-card>
              <p-card *ngIf="item.type == 5" id="loadingCard" class="notification-card">
                <div>{{ item.content }}</div>
              </p-card>
              <p-card *ngIf="item.type == 6" id="errorCard" class="notification-card">
                <div style="position: relative;">
                  <button id="trashBtn" (click)="deleteSelectedNotification(item.key)" pButton type="button" icon="pi pi-trash" class="p-button-square p-button-danger"></button>
                  <div>
                    {{ item.content }}
                    <a href="{{ item.link }}" target="_">Ver detalles.</a>
                  </div>
                </div>
              </p-card>
            </div>
          </div>
          <div class="container-delete-all">
            <button id="deleteAllBtn" (click)="deleteAllNotifications()" pButton type="button" icon="pi pi-trash" class="p-button-square p-button-danger p-button-sm">Eliminar Todos</button>
          </div>
    </ng-template>
</p-overlayPanel>
<i
  class="pi pi-bell p-text-secondary"
  pBadge
  value="{{ quantity }}"
  (click)="quantity !== 0 && notifications.toggle($event)"
  (onClick)="quantity !== 0 && showNotifications()"
  [style]="{ 'font-size': '1.5rem', color: 'white', cursor: quantity !== 0 ? 'pointer' : 'not-allowed' }"
></i>