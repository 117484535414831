import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyclp'
})
export class CurrencyCLPPipe implements PipeTransform {

  transform(value: number): string {
    if (isNaN(value)) {
      return value.toString();
    }
    return '$' + value.toLocaleString('es-CL', { minimumFractionDigits: 0 });
  }

}
