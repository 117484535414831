import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-navbar-menu',
  templateUrl: './navbar-menu.component.html',
  styleUrls: ['./navbar-menu.component.scss']
})
export class NavbarMenuComponent implements OnInit {

  telecomBool: boolean = false;
  constructor(
    private router: Router,
    private authUser: AuthService
  ) { }

  ngOnInit(): void {
  }
hiddenSidebar(){
  let telecom = document.getElementById('left-menu');
    if (!this.telecomBool) {
      telecom!.style.display = 'none';
      this.telecomBool = true;
    } else {
      telecom!.style.display = 'block';
      this.telecomBool = false;
    }
}

  logOut() {
  localStorage.clear();
  this.router.navigate(['']);
}


}
