// src/app/shared/shared.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyCLPPipe } from '../pipes/currencyclp.pipe';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { NavbarMenuComponent } from './navbar-menu/navbar-menu.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { NotificationButtonComponent } from './notification-button/notification-button.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BadgeModule } from 'primeng/badge';
import { CardModule } from 'primeng/card';

@NgModule({
  declarations: [
    CurrencyCLPPipe,
    SidebarMenuComponent,
    NavbarMenuComponent,
    NotificationButtonComponent
  ],
  imports: [
    CommonModule,
    PanelMenuModule,
    MenubarModule,
    ButtonModule,
    OverlayPanelModule,
    BadgeModule,
    CardModule
  ],
  exports: [
    CurrencyCLPPipe,
    SidebarMenuComponent,
    NavbarMenuComponent,
  ]
})
export class SharedModule { }
